import React from "react"
import styled from "styled-components"
import { SiteSiteMetadataAuthor } from "graphql-types"
import SectionLayout from "../WrapperSections/SectionLayout"
import TitleDescriptionSection from "../TitleDescriptionSection"
import DotsGroup from "../DecorationElements/DotsGroup"
import ElementIcons from "../DecorationElements/ElementsIcons"

import { device } from "../../styles/constants"
interface TempIntroProps {
  author: SiteSiteMetadataAuthor
  padeData: Record<string, any>
}

const TempIntro: React.FunctionComponent<TempIntroProps> = ({
  padeData,
}): React.ReactElement => {
  return (
    <Layout>
      <InnerContainer>
        <DotsGroup quantity={66} left="53%" top="12%" type="big" />
        <DotsGroup quantity={77} left="13%" top="62%" type="big" />
        <ElementIcons
          type="icon-circle"
          left="97%"
          top="38%"
          size="91px"
          opacity="0.22"
        />
        <ElementIcons
          type="icon-wave"
          left="25%"
          top="13%"
          size="91px"
          opacity="0.47"
        />
        <ElementIcons
          type="icon-pencil"
          left="11%"
          top="49%"
          size="58px"
          opacity="0.41"
        />
        <ElementIcons
          type="icon-figure"
          left="65%"
          top="70%"
          size="122px"
          opacity="0.28"
        />
        <TitleDescriptionSection
          alignContent="center"
          bgColor="#DAAFC3"
          data={padeData}
          padding="180px 136px 220px"
          marginBottomTitle="96px"
          classChildName="big-layout"
        />
      </InnerContainer>
    </Layout>
  )
}

const InnerContainer = styled.div`
  width: 100%;
  max-width: ${props => props.maxWidth};
  margin: -32px auto 0;
  position: relative;
  @media ${device.laptop} {
    padding: 0;
  }
  @media ${device.mobileL} {
    padding: 0;
    margin: 0 auto;
  }
`

const Layout = styled.div`
  max-width: 1600px;
  text-align: ${props => props.alignContent};
  @media ${device.desktop} {
    max-width: 1300px;
    margin: 0 auto;
  }
  @media ${device.laptopL} {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 64px;
  }
  @media ${device.tablet} {
    padding: 0;
  }
`

export default TempIntro
