import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { SiteSiteMetadataAuthor } from "graphql-types"
import Image from "gatsby-image"

import SectionSideBySide from "../WrapperSections/SectionSideBySide"
import TitleDescriptionSection from "../TitleDescriptionSection"

const MapComponentTitleDescriptionData = {
  title:
    "They sure do. Our technology is used in various industries, all over the world.",
  description: "You've got me! Can I see more?",
}

interface MapComponentProps {
  author: SiteSiteMetadataAuthor
}

const MapComponent: React.FunctionComponent<
  MapComponentProps
> = ({}): React.ReactElement => {
  const imageMap = useStaticQuery(graphql`
    query {
      file(absolutePath: { regex: "/mapImage.png/" }) {
        childImageSharp {
          fluid(maxWidth: 1100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <SectionSideBySide
      overlayPerc="-10%"
      imageSize={14}
      textSide={
        <TitleDescriptionSection
          alignContent="left"
          bgColor="#DAAFC3"
          data={MapComponentTitleDescriptionData}
          marginBottomTitle="23px"
          padding="170px 142px 220px 84px"
          classChildName="medium-layout"
        />
      }
      imageSide={
        <Image fluid={imageMap.file.childImageSharp.fluid} alt="logo" />
      }
    />
  )
}

export default MapComponent
