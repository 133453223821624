import React from "react"

import SectionSideBySide from "../WrapperSections/SectionSideBySide"
import TitleDescriptionSection from "../TitleDescriptionSection"
import ImagesGroup from "./ImagesGroup"
import ElementIcons from "../DecorationElements/ElementsIcons"

const mainPageTitleDescriptionData = {
  title:
    "We at Flipbase want to make recruitment more personal, effective and fun.",
  description: "As a recruitment professional I'd like that too! But how?",
}

interface IntroFirst {}

const IntroFirst: React.FunctionComponent<
  IntroFirst
> = ({}): React.ReactElement => {
  return (
    <>
      <ElementIcons
        type="icon-points"
        left="53%"
        top="18%"
        size="91px"
        opacity="0.8"
      />
      <ElementIcons
        type="icon-wave"
        left="30%"
        top="72%"
        size="91px"
        opacity="0.41"
      />
      <ElementIcons
        type="icon-pencil"
        left="41%"
        top="29%"
        size="58px"
        opacity="0.41"
      />
      <ElementIcons
        type="icon-film"
        left="70%"
        top="100%"
        size="58px"
        opacity="0.41"
      />
      <ElementIcons
        type="icon-rectangle"
        left="95%"
        top="92%"
        size="122px"
        opacity="0.7"
      />
      <ElementIcons
        type="icon-figure"
        left="65%"
        top="24%"
        size="122px"
        opacity="0.42"
      />
      <SectionSideBySide
        textSide={
          <TitleDescriptionSection
            alignContent="left"
            bgColor="#DAAFC3"
            data={mainPageTitleDescriptionData}
            marginBottomTitle="23px"
            padding="170px 84px 220px"
            classChildName="medium-layout"
          />
        }
        imageSide={<ImagesGroup />}
      />
    </>
  )
}

export default IntroFirst
