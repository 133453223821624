import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { colors, device } from "../../styles/constants"
import { Col, Modal } from "antd"
import DotsGroup from "../DecorationElements/DotsGroup"
import ElementIcons from "../DecorationElements/ElementsIcons"
import IFrameVideo from "../IFrameVideo"

/* Card CardScheme component from the page 4 */

class CardScheme extends React.Component {
  state = { visible: false }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = e => {
    this.setState({
      visible: false,
    })
  }

  handleCancel = e => {
    this.setState({
      visible: false,
    })
  }
  // <DotsGroup quantity={44} left="10%" top="54%" type="big" />

  render() {
    return (
      <>
        <ContentWrapper
          xxl={7}
          lg={6}
          md={24}
          sm={24}
          onClick={this.showModal}
          onMouseOver={this.showModal}
        >
          <IconWrapper>
            <img src={this.props.data.icon} alt={this.props.data.title} />
          </IconWrapper>
          <Title>{this.props.data.title}</Title>
        </ContentWrapper>
        <StyledModal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          centered
          footer={null}
        >
          <ModalBody>
            <ModalVideoSection>
              <IFrameVideo videoSrc={this.props.data.modalData.videoSrc} />
            </ModalVideoSection>
            <ModalTextSection>
              <TitleModal>{this.props.data.modalData.title}</TitleModal>
              <ElementIcons
                type="icon-two-rec"
                left="60%"
                top="82%"
                size="91px"
                opacity="0.41"
              />
              <ElementIcons
                type="icon-two-rec"
                left="60%"
                top="74%"
                size="91px"
                opacity="0.41"
              />
              <DescriptionModal>
                {this.props.data.modalData.descritpion}
              </DescriptionModal>
            </ModalTextSection>
          </ModalBody>
        </StyledModal>
      </>
    )
  }
}

const ContentWrapper = styled(Col)`
  position: relative;
  overflow: hidden;
  height: 141px;
  width: 300px;
  padding: 30px;
  margin: 0 24px;
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  ::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    top: -20%;
    left: -50%;
    z-index: -1;
    background-color: #daafc3;
    opacity: 0.15;
    transform: rotate(-2deg) skew(2deg, 0deg);
    @media ${device.laptop} {
      height: 110%;
      transform: rotate(0) skew(15deg, 0deg);
      top: -16%;
    }
  }
  @media ${device.desktop} {
    margin: 0 12px;
    padding: 16px;
  }
  @media ${device.laptop} {
    padding: 22px;
    width: 167px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    height: auto;
    margin: 90% auto;
  }
  @media ${device.mobileL} {
    padding: 15px;
    width: 145px;
  }
  @media ${device.mobileS} {
    padding: 8px;
    width: 120px;
  }
`

const Title = styled.h3`
  font-family: FreightSans;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: ${colors.dark};
  margin: 0 0 31px 27px;
  @media ${device.laptopL} {
    margin-left: 16px;
  }
  @media ${device.desktop} {
    font-size: 20px;
    line-height: 22px;
  }
  @media ${device.laptop} {
    margin: 0 !important;
  }
  @media ${device.mobileL} {
    font-size: 17px;
  }
`

const IconWrapper = styled.div`
  width: auto;
  height: 80px;
  min-width: 80px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 31px;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  img {
    margin-bottom: 0;
  }
  @media ${device.desktop} {
    height: 60px;
    min-width: 60px;
    img {
      max-width: 42px;
    }
  }

  @media ${device.laptop} {
    height: 40px;
    min-width: 40px;
    margin-bottom: 9px;
    img {
      max-width: 28px;
    }
  }
`

const StyledModal = styled(Modal)`
  .ant-modal-close-x {
    color: #a62651;
  }
`

const ModalBody = styled.div`
  padding: 16px 26px;
  @media ${device.mobileL} {
    padding: 0;
  }
`

const ModalVideoSection = styled.div`
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
`

const ModalTextSection = styled.div``

const TitleModal = styled.h5`
  font-family: FreightSans;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: ${colors.dark};
  margin-bottom: 22px;
`

const DescriptionModal = styled.p`
  font-family: FreightSans;
  font-size: 18px;
  line-height: 24px;
  color: rgba(35, 29, 48, 0.6);
`

const ImageTitle = styled.img`
  position: relative;
  z-index: 10;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
`

const ImageIcon = styled.img`
  position: relative;
  z-index: 10;
  margin-bottom: 0;
`

const IconModalWrapper = styled.div`
    position:absolute;
    overflow:hidden;
    width:92px;
    display:flex !important;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    padding:16px 16px 40px;
    left:15%;
    top:50%:
    margin-top:-46px;
    z-index:11;
    ::before {
        content: "";
        position: absolute;
        width:200%;
        height:100%;
        top:-20%;
        left:-50%;
        background-color:#ffffff;
        transform: rotate(-10deg) skew(12deg, 0deg);
    }
`

export default CardScheme
