import React from "react"
import styled from "styled-components"
import { device } from "../../styles/constants"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import "./swiper.css"

/* Section Icons from page with map  */

interface SocialProps {
  speed: integer
}

const Social: React.FunctionComponent<SocialProps> = ({
  icons,
  speed,
  slidesView,
}): React.ReactElement => {
  const params = {
    slidesPerView: slidesView,
    spaceBetween: 50,
    initialSlide: 0,
    speed: speed,
    loop: true,
    freeMode: true,
    autoplay: {
      delay: 1,
      disableOnInteraction: false,
    },
  }

  return (
    <LayoutComponent>
      <SocialWrapper>
        <ScrollWrapper>
          <ExtendedSwiper {...params}>
            {icons.map(icon => {
              return (
                <IconWrapper>
                  <img src={icon.icon} />
                </IconWrapper>
              )
            })}
          </ExtendedSwiper>
        </ScrollWrapper>
      </SocialWrapper>
    </LayoutComponent>
  )
}

const ExtendedSwiper = styled(Swiper)`
  transition-timing-function: linear !important;
`

const LayoutComponent = styled.div`
  margin: 0 auto;
  margin-top: 32px;
  max-width: 1600px;
  position: relative;

  @media ${device.desktop} {
    max-width: 1300px;
  }

  @media ${device.laptopL} {
    max-width: 1200px;
    margin: 0 auto 96px;
  }

  @media (max-width: 1200px) {
    padding: 0 64px;
    margin: 0 auto;
  }
  @media ${device.tablet} {
    padding: 0;
  }
  @media ${device.mobileL} {
    margin-top: -10px;
  }
  @media ${device.mobileM} {
    margin-bottom: 96px;
    margin-top: 16px;
  }
`

const SocialWrapper = styled.div`
  margin-top: -64px;
  @media (min-width: 1900px) {
    margin-top: 0;
  }
  @media ${device.mobileL} {
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    div {
      width: 200%;
      height: auto;
    }
  }
`

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media ${device.laptopL} {
    padding: 0 64px;
  }
  @media ${device.tablet} {
    padding: 0 32px;
  }
  @media ${device.mobileL} {
    padding: 0 16px;
    margin: -16px 0 32px;
  }
`
const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img {
    float: left;
    width: 160px;
    height: 120px;
    object-fit: contain;
  }
`

export default Social

/*overflow-x: ${props => props.scroll};
  width: ${({ scroll }) => {
    if (scroll === "scroll") return "100%"
  }};
  div {
    width: ${({ scroll }) => {
      if (scroll === "scroll") return "200%"
    }};
    height: auto;
}*/
