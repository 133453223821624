import React from "react"

import styled from "styled-components"

interface IFrameVideoProps {
  videoSrc: string
}

const IFrameVideo: React.FunctionComponent<IFrameVideoProps> = ({
  videoSrc,
}): React.ReactElement => {
  return (
    <FrameWrapper>
      <StyledIframe
        frameborder="0"
        src={videoSrc}
      ></StyledIframe>
    </FrameWrapper>
  )
}

const FrameWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0px;
  height: 0;
  overflow: hidden;
`

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none !important;
  background-color: none !important;
`

export default IFrameVideo
