import React, { ReactNode } from "react"
import styled from "styled-components"
import SectionLayout  from "../SectionLayout"
import { Col, Row } from "antd"
import { colors, device } from "../../../styles/constants";

/* SectionSideBySide */

interface SectionSideBySide {
    justify: string,
    maxWidth: string,
    gutter: string,
    textSide: ReactNode,
    imageSide: ReactNode,
    imageSize: number,
    overlayPerc: string,
}

const SectionSideBySide: React.FunctionComponent<SectionSideBySide> = ({
    justify,
    children,
    maxWidth,
    gutter,
    textSide,
    imageSide,
    imageSize,
    overlayPerc,
    ...otherProps
}): React.ReactElement => {

  
  return (
        <SectionLayout>
            <SectionRow
                type="flex"
                justify={justify}
                gutter={80}
                >
                <SectionColText xl={12} lg={24} md={24} sm={24} xs={24}>
                    {textSide} 
                </SectionColText>
                <SectionColImage xl={imageSize} lg={24} md={24} sm={24} xs={24} overlayPerc={overlayPerc}>
                    {overlayPerc && (
                        <ScrollWrapper>{imageSide}</ScrollWrapper>
                    )}
                    {!overlayPerc && (
                        <>{imageSide}</>
                    )}
                </SectionColImage>
            </SectionRow>
        </SectionLayout>
  );
};

SectionSideBySide.defaultProps = {
  imageSize: 12,
}

const SectionRow = styled(Row)`
  margin-left: 0px !important;
  margin-right: 0px !important;
  
  ${props =>
    props.small
      ? `
        max-width:${props => props.maxWidth};
        margin:0 auto !important;
        justify-content:space-between;
        .ant-col {
          padding-left: 40px !important;
          padding-right: 40px !important;
        }
      `
      : ``};
`

const SectionColText = styled(Col)`
  padding-left: 0 !important;
  padding-right: 0 !important;
  @media (max-width: 1200px) {
    margin-bottom:-224px;
  }
  @media (max-width: 576px) {
    padding-bottom: 20px;
    width: 100%;
  }
`
const SectionColImage = styled(Col)`
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top:96px;
  margin-left:${props => props.overlayPerc};
  @media (max-width: 1200px) {
    margin-left:0;
  }
  @media (max-width: 991px) {
    margin-top:32px;
  }

  @media (max-width: 576px) {
    padding-bottom: 20px;
    width: 100%;
  }
`

const ScrollWrapper = styled.div `
  @media ${device.mobileL} {
    width:100%;
    height:100%;
    overflow-x:scroll;
    div {
      width:200%;
      height:auto;
    }
  }
  
`




export default SectionSideBySide;

