import React from "react"
import ReactFullpage from "@fullpage/react-fullpage"
import styled from "styled-components"
import SEO from "../components/Seo/Seo"

import ReasonsComponent from "../components/ReasonsComponent"
import IntroFirst from "../components/IntroFirst"
import TempIntro from "../components/TempIntro"
import Scheme from "../components/Scheme"
import MapComponent from "../components/MapComponent"
import Social from "../components/Social"
import FooterContact from "../components/ContactComponents/FooterContact"
import Layout from "../components/Layout"

const introductionMetadata = {
  Title: "Flipbase - Integrated Video Recruitment",
  Keywords: [
    "Flipbase",
    "integrated video recruitment",
    "video for recruitment",
  ],
  Description:
    "Flipbase is a software company, and we offer integrated asynchronous video communication for recruitment professionals. Check out our new website for more information.",
  Image: "/seo-image-general.png",
}

const secondIntroData = {
  title: "By integrating video in your everyday work.",
  description:
    "Interesting! And by 'integrating' you mean in my current recruitment system?",
}

const fourtthIntroData = {
  title:
    "Indeed! We make it easy for you because we can fully integrate it into your existing workflow.",
  description:
    "What do you mean by ‘integrate’? Do you mean in my current recruitment system?",
}

const socialIcons = [
  {
    icon: "/partner_lumesse.png",
  },
  {
    icon: "/partner_varbi.png",
  },
  {
    icon: "/partner_carerix.png",
  },
  {
    icon: "/partner_hireserve.png",
  },
  {
    icon: "/partner_peoplexs.png",
  },
]

const fiveIntroData = {
  title: "That's right! We can be integrated in any recruitment system.",
  description: "That's great! But what is in it for me?",
}

const socialIconsRow = [
  {
    icon: "/customer_frieslandcampina.png",
  },
  {
    icon: "/customer_sunweb.png",
  },
  {
    icon: "/customer_mcdonalds.png",
  },
  {
    icon: "/customer_pathe.png",
  },
  {
    icon: "/customer_gemeenteamsterdam.png",
  },
  {
    icon: "/customer_anwb.png",
  },
  {
    icon: "/customer_usgpeople.png",
  },
  {
    icon: "/customer_conclusion.jpg",
  },
  {
    icon: "/customer_klm.png",
  },
  {
    icon: "/customer_belastingdienst.png",
  },
  {
    icon: "/customer_olvg.png",
  },
  {
    icon: "/customer_apg.png",
  },
]

const tenIntroData = {
  title: "Of course! Just schedule a demo and we will meet soon.",
  description: "Sounds like a plan!",
  calltoAction: "Schedule demo",
}

class Introduction extends React.Component {
  constructor(props) {
    super(props)
  }

  

  // scrollBar={true}
  // scrollOverflow={true}

  render() {
    return (
      <Layout location={this.props.location}>
        <SEO
          title={introductionMetadata.Title}
          description={introductionMetadata.Description}
          image={introductionMetadata.Image}
          keywords={introductionMetadata.Keywords}
        />
        <ReactFullpage
          navigation={true}
          scrollingSpeed={500}
          licenseKey={"C3F13088-6C034231-A977A159-87CDD2B7"}
          render={({ state, fullpageApi }) => {
            return (
              <div id="fullpage-wrapper">
                <div className="section section1">
                  <IntroFirst location={this.props.location} />
                </div>
                <div className="section section2">
                  <TempIntro padeData={secondIntroData} />
                </div>
                <div className="section section3">
                  <TempIntro padeData={fiveIntroData} />
                  <Social icons={socialIcons} speed={4000} slidesView="3" />
                </div>
                <div className="section section4">
                  <ReasonsComponent />
                </div>
                <div className="section section5">
                  <Scheme />
                </div>
                <div className="section section6">
                  <MapComponent />
                  <Social icons={socialIconsRow} speed={3000} slidesView="6" />
                </div>
                <div className="section section7">
                  <TempIntro padeData={tenIntroData} />
                  <FooterContact
                    paddingLaptop="20px 120px 20px 120px;"
                    elPosition="absolute"
                  />
                </div>
              </div>
            )
          }}
        />
      </Layout>
    )
  }
}

const SociaWrapper = styled.div`
  margin-top: -96px;
  @media (min-width: 1900px) {
    margin-top: 0;
  }
`

const TempWrapper = styled.div`
  margin-top: -32px;
`

export default Introduction
