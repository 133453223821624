import React from "react"
import styled from "styled-components"
import { device, colors } from "../../styles/constants"

import { SpringFadeUp } from "../../utils/animation"
import ModalHeader from "../Layout/ModalHeader"
/* Section Title Description with matrix background  */

interface SectionTitleDescription {
  alignContent: string
  bgColor: string
  data: Record<string, any>
  padding: string
  marginBottomTitle: string
  classChildName: string
}

const SectionTitleDescription: React.FunctionComponent<
  SectionTitleDescription
> = ({
  alignContent,
  bgColor,
  data,
  padding,
  marginBottomTitle,
  classChildName,
}): React.ReactElement => {
  return (
    <ContentWrapper
      alignContent={alignContent}
      bgColor={bgColor}
      padding={padding}
      className={classChildName}
    >
      {data.firstTitle && <Title>{data.firstTitle}</Title>}

      <Title marginBottom={marginBottomTitle}>{data.title}</Title>

      <SpringFadeUp>
        <Description>{data.description}</Description>
        {data.calltoAction && (
          <CtaWrapper>
            <ModalHeader callToAction={data.calltoAction} />
          </CtaWrapper>
        )}
      </SpringFadeUp>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  position: relative;
  z-index: 100;
  overflow: hidden;
  width: 100%;
  text-align: ${props => props.alignContent};
  padding: ${props => props.padding};
  ::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    top: -20%;
    left: -50%;
    z-index: -1;
    background-color: ${props => props.bgColor};
    opacity: 0.15;
    transform: rotate(-8deg) skew(2deg, 0deg);
  }
  button {
    @media ${device.laptopL} {
      max-width: ${({ className }) => {
        if (className === "big-layout") return "100%"
        if (className === "medium-layout") return "600px"
      }};

      font-size: ${({ className }) => {
        if (className === "big-layout") return "36px"
        if (className === "medium-layout") return "38px"
      }};
      line-height: ${({ className }) => {
        if (className === "big-layout") return "44px"
        if (className === "medium-layout") return "50px"
      }};

      margin-bottom: ${({ className }) => {
        if (className === "big-layout") return "54px"
        if (className === "medium-layout") return "28px"
      }};
    }
    @media ${device.mobileL} {
      font-size: ${({ className }) => {
        if (className === "big-layout") return "24px"
        if (className === "medium-layout") return "24px"
      }};
      line-height: ${({ className }) => {
        if (className === "big-layout") return "31px"
        if (className === "medium-layout") return "29px"
      }};
      margin-bottom: ${({ className }) => {
        if (className === "big-layout") return "28px"
        if (className === "medium-layout") return "21px"
      }};
      text-align: left;
    }
  }
  h3 {
    @media ${device.laptopL} {
      max-width: ${({ className }) => {
        if (className === "big-layout") return "100%"
        if (className === "medium-layout") return "600px"
      }};

      font-size: ${({ className }) => {
        if (className === "big-layout") return "48px"
        if (className === "medium-layout") return "38px"
      }};
      line-height: ${({ className }) => {
        if (className === "big-layout") return "56px"
        if (className === "medium-layout") return "50px"
      }};

      margin-bottom: ${({ className }) => {
        if (className === "big-layout") return "54px"
        if (className === "medium-layout") return "28px"
      }};
    }
    @media ${device.mobileL} {
      font-size: ${({ className }) => {
        if (className === "big-layout") return "32px"
        if (className === "medium-layout") return "24px"
      }};
      line-height: ${({ className }) => {
        if (className === "big-layout") return "36px"
        if (className === "medium-layout") return "29px"
      }};
      margin-bottom: ${({ className }) => {
        if (className === "big-layout") return "28px"
        if (className === "medium-layout") return "21px"
      }};
      text-align: left;
    }
  }
  p {
    @media ${device.tablet} {
      font-size: ${({ className }) => {
        if (className === "big-layout") return "30px"
        if (className === "medium-layout") return "22px"
      }};
      line-height: ${({ className }) => {
        if (className === "big-layout") return "38px"
        if (className === "medium-layout") return "28px"
      }};
    }
    @media ${device.mobileL} {
      text-align: left;
      font-size: ${({ className }) => {
        if (className === "big-layout") return "24px"
        if (className === "medium-layout") return "18px"
      }};
      line-height: ${({ className }) => {
        if (className === "big-layout") return "31px"
        if (className === "medium-layout") return "23px"
      }};
    }
  }
  @media ${device.mobileL} {
    text-align: left !important;
  }
`

const Title = styled.p`
  font-family: FreightSans;
  display: inline-block;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: ${colors.dark};
  margin-bottom: ${props => props.marginBottom};

  @media ${device.tablet} {
    margin-bottom: 60px;
  }
  @media ${device.mobileL} {
    margin-bottom: 32px;
  }
`
const CtaWrapper = styled.div`
  margin-top: 30px;
  @media ${device.tablet} {
    margin-top: 40px;
  }
  @media ${device.mobileL} {
    margin-top: 18px;
  }
`

const Description = styled.p`
  font-family: FreightSans;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: rgba(35, 29, 48, 0.6);
`

export default SectionTitleDescription
