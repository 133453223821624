import React from "react"
import styled from "styled-components"
import { device, colors } from "../../styles/constants"
import { Col, Row } from "antd"
import { SpringFadeUp } from "../../utils/animation"

/* Simple Title Description */

interface SimpleTitleDescription {
  data: Record<string, any>
}

const SimpleTitleDescription: React.FunctionComponent<
  SimpleTitleDescription
> = ({ data, align, xlSizeTitle, xlSizeDescription }): React.ReactElement => {
  return (
    <ContentWrapper>
      <Row type="flex" justify="space-between" align={align}>
        <Col xl={xlSizeTitle} lg={15} md={24} sm={24} xs={24}>
          <Title>{data.title}</Title>
        </Col>
        <Col xl={xlSizeDescription} lg={8} md={24} sm={24} xs={24}>
          <SpringFadeUp>
            <Description>{data.description}</Description>
          </SpringFadeUp>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  @media ${device.laptopL} {
    padding: 0 30px;
  }
  @media ${device.mobileL} {
    padding: 0 16px;
  }
`

const Title = styled.h3`
  font-family: FreightSans;
  text-align: center;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: ${colors.dark};
  @media ${device.tablet} {
    font-size: 41px;
    line-height: 50px;
    margin-bottom: 31px;
  }
  @media ${device.mobileL} {
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 21px;
  }
`

const Description = styled.p`
  font-family: FreightSans;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: rgba(35, 29, 48, 0.6);
  @media ${device.tablet} {
    font-size: 22px;
    line-height: 28px;
  }
  @media ${device.mobileL} {
    font-size: 18px;
    line-height: 23px;
  }
`

export default SimpleTitleDescription
