import React from "react"
import styled from "styled-components"
import { device, colors } from "../../styles/constants"
import { Col, Row } from "antd"
import ElementIcons from "../DecorationElements/ElementsIcons"

/* Card Reason component from the page 5 */

interface CardReason {
  data: Record<string, any>
}

const CardReason: React.FunctionComponent<CardReason> = ({
  data,
}): React.ReactElement => {
  const title = data.breakTitle ? (
    <Title>
      {data.title.split(" ")[0]} <br />
      {data.title.split(" ")[1]}
    </Title>
  ) : (
    <Title>{data.title}</Title>
  )

  return (
    <ContentWrapper xl={5} md={10}>
      <IconWrapper>
        <img src={data.icon} alt={`${data.firstTitle} ${data.secondTitle}`} />
      </IconWrapper>
      {title}
      <Description>{data.description}</Description>
      <ElementIcons type={data.elIcon} left="71%" top="65%" opacity="0.2" />
      <ElementIcons type="icon-rectangle" left="70%" top="-8%" opacity="0.7" />
    </ContentWrapper>
  )
}

const ContentWrapper = styled(Col)`
  position: relative;
  overflow: hidden;
  width: 325px;
  height: 380px;
  padding: 30px 40px 63px;
  margin: 0 24px;
  ::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    top: -20%;
    left: -50%;
    background-color: #daafc3;
    opacity: 0.15;
    transform: rotate(-8deg) skew(2deg, 0deg);
  }
  @media ${device.laptopL} {
    padding: 30px 16px 63px;
    margin: 0;
  }
  @media (max-width: 1200px) {
    margin: 0 auto;
    padding: 30px 40px 63px;
    height: 320px;
  }
  @media ${device.tablet} {
    margin: 0 auto;
    padding: 30px 40px;
    height: 350px;
  }
  @media ${device.mobileL} {
    max-width: 340px;
    width: 100%;
    margin: 0 24px 32px;
    padding: 24px 16px;
    height: 320px;
  }
`

const Title = styled.h3`
  font-family: FreightSans;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: ${colors.dark};
`

const SecondTitle = styled.h3`
  text-align: center;
  font-family: FreightSans;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: ${colors.dark};
`

const Description = styled.p`
  font-family: FreightSans;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: rgba(35, 29, 48, 0.6);
`
const IconWrapper = styled.div`
  width: 80px;
  height: 80px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  img {
    margin-bottom: 0;
  }
`

export default CardReason
