import React from "react";
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled from 'styled-components'
import { device } from "../../../styles/constants";

interface ImagesGroup {
  
}


const ImagesGroup: React.FunctionComponent<ImagesGroup> = ({
  
}): React.ReactElement => {

    const homeImages = useStaticQuery(graphql`
    query CaseImages {
      boySmile: file(absolutePath: {regex: "/boy-smile.png/"}) {
        childImageSharp {
          sizes(maxWidth: 400) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      girlCall: file(absolutePath: {regex: "/girl-call.png/"}) {
        childImageSharp {
          sizes(maxWidth: 400) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      girlTalking: file(absolutePath: {regex: "/girl-talking.png/"}) {
        childImageSharp {
          sizes(maxWidth: 400) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      happyHuman: file(absolutePath: {regex: "/happy-human.png/"}) {
        childImageSharp {
          sizes(maxWidth: 400) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      imageInMobile: file(absolutePath: {regex: "/image-inmobile.png/"}) {
        childImageSharp {
          sizes(maxWidth: 400) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      manBarb: file(absolutePath: {regex: "/man-barb.png/"}) {
        childImageSharp {
          sizes(maxWidth: 400) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `)
  return (
    <ImagesWrapper>
        <ImageItemWrapper>
            <Image
                sizes={homeImages.boySmile.childImageSharp.sizes}
            />
        </ImageItemWrapper>
        <ImageItemWrapper>
            <Image 
                sizes={homeImages.girlCall.childImageSharp.sizes}
            />
        </ImageItemWrapper>
        <ImageItemWrapper>
            <Image 
                sizes={homeImages.girlTalking.childImageSharp.sizes}
            />
        </ImageItemWrapper>
        <ImageItemWrapper>
            <Image
                sizes={homeImages.happyHuman.childImageSharp.sizes}
            />
        </ImageItemWrapper>
        <ImageItemWrapper>
            <Image 
                sizes={homeImages.imageInMobile.childImageSharp.sizes}
            />
        </ImageItemWrapper>
        <ImageItemWrapper>
            <Image 
                sizes={homeImages.manBarb.childImageSharp.sizes}
            />
        </ImageItemWrapper>
   </ImagesWrapper>
  );
};


export const ImagesWrapper = styled.div`
    height:auto;
    max-height:100%;
    @media ${device.laptopL} {
        height:600px;
    }
    @media ${device.tablet} {
        height:629px;
    }
    @media ${device.mobileL} {
      height:429px;
  }
`

export const ImageItemWrapper = styled.div`
    position:absolute;
    top:0;
    right:0;
    width:136px;
    height:152px;
    :nth-of-type(1) {
        top:25%;
        width:391px;
        z-index:4;
        right:-10%;
        @media (max-width:1200px) {
            right:0%;
            top:20%;
        }
        @media ${device.laptopL} {
          width:341px;
          top:14%;
        }
        @media ${device.tablet} {
            width:293px;
        }
        @media ${device.mobileL} {
          width:152px;
          top:22%;
          right:-5%;
        }
    }
    :nth-of-type(2) {
        top:10%;
        width:328px;
        right:46%;
        z-index:3;
        @media (max-width:1200px) {
            right:46%;
            top:5%;
        }
        @media ${device.laptopL} {
            width:258px;
            right:56%;
        }
        @media ${device.tablet} {
            width:246px;
        }
        @media ${device.mobileL} {
          width:155px;
          top:12%;
          right:54%;
        }
    }
    :nth-of-type(3) {
        top:4%;
        width:622px;
        right:0;
        z-index:1;
        @media (max-width:1200px) {
            right:10%;
            top:0%;
        }
        @media ${device.laptopL} {
            width:550px;
        }
        @media ${device.tablet} {
            width:466px;
        }
        @media ${device.mobileL} {
          width:333px;
          top:6%;
          right:-1%;
        }
    }
    :nth-of-type(4) {
        top:50%;
        width:359px;
        right:12%;
        z-index:6;
        @media (max-width:1200px) {
            right:20%;
            top:39%;
        }
        @media ${device.laptopL} {
            width:248px;
            top:45%;
            right:20%;
        }
        @media ${device.tablet} {
            width:269px;
        }
        @media ${device.mobileL} {
          width:154px;
          top:40%;
          right:15%;
        }
    }
    :nth-of-type(5) {
        top:28%;
        width:338px;
        right:62%;
        z-index:1;
        @media (max-width:1200px) {
            right:62%;
            top:23%;
        }
        @media ${device.laptopL} {
            width:297px;
            right:72%;
        }
        @media ${device.tablet} {
            width:253px;
        }
        @media ${device.mobileL} {
          width:134px;
          top:25%;
          right:72%;
        }
    }
    :nth-of-type(6) {
        top:-24%;
        width:167px;
        z-index:0;
        right:8%;
        
        @media (max-width:1600px) {
            top:-18%;
        }
        @media ${device.laptopL} {
            width:146px;
        }
        @media (max-width:1200px) {
          right:18%;
        }
        @media ${device.tablet} {
            width:125px;
            top:-19%;
        }
        @media ${device.mobileL} {
          width:80px;
          top:-9%;
        }
    }
`



export default ImagesGroup;
