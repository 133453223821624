import React from "react"
import { SiteSiteMetadataAuthor } from "graphql-types"
import SectionLayout from "../../components/WrapperSections/SectionLayout"
import styled from "styled-components"
import { Row, Col } from "antd"
import { colors, device } from "../../styles/constants"
import { SpringFadeUp } from "../../utils/animation"
import CardScheme from "../../components/CardsScheme"
import SimpleTitleDescription from "../../components/SimpleTitleDescritpion"

const titleDescriptionData = {
  title: "That depends on the procedure, but here are some examples:",
  description: "",
}

const cardsData = [
  {
    title: "Video Motivation",
    icon: "/apply.png",
    modalData: {
      title:
        "Provide applicants the opportunity to distinguish themselves from others",
      descritpion:
        "Video Motivation popup description: Make sure you don't miss out on hidden talent by also taking soft skills into consideration and not solely assess people based on hard skills",
      videoSrc:
        "https://app.flipbase.com/embed/da898b00-de2f-41b4-aa65-d11b371affdc",
      icon: "/hand.png",
    },
  },
  {
    title: "Video Questionnaire",
    icon: "/question.png",
    modalData: {
      title: "Assess a candidate's personality, cultural and job fit",
      descritpion:
        "Invite a selection of candidates to answer some job or company specific questions in video to avoid unnecessary phone intakes or first round live interviews",
      videoSrc:
        "https://app.flipbase.com/embed/d2720338-7ee2-4582-806a-03e40357ac3d",
      icon: "/questions.png",
    },
  },
  {
    title: "Video Presentation",
    icon: "/presentation.png",
    modalData: {
      title: "Test the candidate's job specific knowledge",
      descritpion:
        "Invite a selection of candidates to answer some repsonal questions in video to avoid scheduled phone intakes or first round live interviews",
      videoSrc:
        "https://app.flipbase.com/embed/45b13e91-a7ac-4ab0-a36e-d90337a36d8f",
      icon: "/think.png",
    },
  },
]

interface SchemeProps {
  author: SiteSiteMetadataAuthor
}

const Scheme: React.FunctionComponent<
  SchemeProps
> = ({}): React.ReactElement => {
  return (
    <SectionLayout>
      <SectionContainer>
        <SimpleTitleDescription
          xlSizeDescription={0}
          xlSizeTitle={24}
          data={titleDescriptionData}
          align="top"
        />
      </SectionContainer>
      <SchemeWrapper>
        <RowLogo align="middle">
          <Col xl={3} sm={24} xs={20}>
            <TextWrapper></TextWrapper>
          </Col>
          <Col xxl={20} xl={20} lg={24} sm={24} xs={24}>
            <MiniLogoWrapper>
              <img src="/miniLogo.png" alt="mini-logo" />
            </MiniLogoWrapper>
          </Col>
          <LineTop rotateAngle="12deg" leftPos="55%"></LineTop>
          <LineTop rotateAngle="-12deg" leftPos="30%"></LineTop>
          <LineTopCenter />
        </RowLogo>

        <RowCards align="middle">
          <Col xl={3} sm={24} lg={24} sm={24} xs={24} md={24}>
            <TextWrapper>
              <div>USE CASES</div>
            </TextWrapper>
          </Col>
          <ColumnCards xxl={20} xl={20} lg={24} sm={24} xs={24}>
            <CardGroupWrapper justify="center" align="middle">
              {cardsData.map((card, index) => {
                return <CardScheme data={card} index={index} />
              })}
            </CardGroupWrapper>
            <LineCardCenter height="105%" leftPos="50%" topPos="81%" />
            <LineCardCenter height="105%" leftPos="80.9%" topPos="81%" />
            <LineCardCenter
              height="105%"
              leftPos="9.2%"
              rotateAngle="12deg"
              topPos="81%"
            />
            <LineCardCenter
              height="116%"
              leftPos="57.9%"
              rotateAngle="-12deg"
              topPos="76%"
            />
            <LineCardCenter
              height="152%"
              leftPos="29.5%"
              rotateAngle="48deg"
              topPos="60.6%"
            />
            <LineCardCenter
              height="122%"
              leftPos="94.5%"
              rotateAngle="-28deg"
              topPos="68.6%"
            />
          </ColumnCards>
        </RowCards>
        <RowLine align="middle">
          <Col xl={2} sm={24} lg={24} sm={24} xs={24}>
            <TextWrapper>
              <div>Stages</div>
            </TextWrapper>
          </Col>
          <Col xxl={20} xl={20} lg={24} sm={24} xs={24}>
            <LineWithDots>
              <BigDotWrapper left="5%">
                <BigDot>
                  <div></div>
                </BigDot>
                <PointDescription>Application</PointDescription>
              </BigDotWrapper>
              <SmallDot left="25%" />
              <BigDotWrapper left="32%">
                <BigDot>
                  <div></div>
                </BigDot>
                <PointDescription>Pre-selection</PointDescription>
              </BigDotWrapper>
              <SmallDot left="51.1%" />
              <SmallDot left="60.2%" />
              <BigDotWrapper left="64%">
                <BigDot>
                  <div></div>
                </BigDot>
                <PointDescription>Interview</PointDescription>
              </BigDotWrapper>
              <SmallDot left="82%" />
              <BigDotWrapper left="92%">
                <BigDot>
                  <div></div>
                </BigDot>
                <PointDescription>Hire</PointDescription>
              </BigDotWrapper>
              <SmallDot left="98.8%" />
            </LineWithDots>
          </Col>
        </RowLine>
      </SchemeWrapper>
      <SpringFadeUp>
        <TitleScheme>
          That is quite flexible! Do other companies use video like this yet?
        </TitleScheme>
      </SpringFadeUp>
    </SectionLayout>
  )
}

const SectionContainer = styled.div`
  padding-top: 100px;
  @media ${device.laptopL} {
    padding-top: 48px;
  }
  @media ${device.laptop} {
    padding-top: 96px;
  }
  @media ${device.mobileL} {
    padding-top: 32px;
  }
`

const CardGroupWrapper = styled(Row)`
  @media (max-width: 1200px) {
    justify-content: space-around !important;
  }
  @media ${device.laptop} {
    flex-direction: column !important;
  }
`

const SchemeWrapper = styled.div`
  @media ${device.laptop} {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 136px 0 32px;
  }
  @media ${device.mobileL} {
    margin: 0 136px 0 16px;
    padding: 48px 0 128px;
  }
`
const TextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  :nth-of-type(1) {
    div {
      height: 87px;
      padding-top: 24px;
    }
  }
  div {
    font-size: 19px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    @media (max-width: 1200px) {
      margin: 48px 0 48px 48px;
    }
    @media ${device.laptop} {
      display: none;
    }
  }
`

const RowCards = styled(Row)`
  padding-bottom: 80px;
  position: relative;

  @media ${device.laptopL} {
    margin: 0 32px;
    max-width: 980px;
    margin: 0 auto;
    padding-bottom: 24px;
  }

  @media (max-width: 1800px) {
    max-width: 1400px;
    margin: 0 auto;
  }
  @media ${device.mobileL} {
    padding: 0;
  }
`

const ColumnCards = styled(Col)`
  @media ${device.laptop} {
    height: 1200px;
    display: flex !important;
    flex-direction: column;
    justify-content: space-around;
  }
`
const RowLogo = styled(Row)`
  padding: 50px 0;
  position: relative;
  @media ${device.laptopL} {
    margin: 0 32px;
    max-width: 980px;
    margin: 0 auto;
    padding: 0 0 32px;
  }
  @media ${device.laptop} {
    padding: 0 0 48px;
  }
  @media (max-width: 1800px) {
    max-width: 1400px;
    margin: 0 auto;
  }
  @media (max-width: 576px) {
    padding: 0 32px;
  }
  @media ${device.mobileL} {
    display: none !important;
  }
`

const RowLine = styled(Row)`
  position: relative;
  @media ${device.laptop} {
    padding-bottom: 32px;
  }
  @media ${device.laptopL} {
    margin: 0 32px;
    max-width: 980px;
    margin: 0 auto;
  }
  @media ${device.laptop} {
    flex-direction: row !important;
    max-width: 120px !important;
    width: 100%;
    margin: 0 !important;
  }

  @media (max-width: 1800px) {
    max-width: 1400px;
    margin: 0 auto;
  }
  @media (max-width: 576px) {
    padding: 0 32px;
    margin-bottom: 96px;
  }
  @media ${device.mobileL} {
    padding: 0 12px;
  }
  @media ${device.mobileS} {
    padding: 0;
  }
`

const MiniLogoWrapper = styled.div`
  width: 97px;
  height: 97px;
  background-color: ${colors.mainLight};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  z-index: 10;
  img {
    margin-bottom: 0;
  }
`
const LineWithDots = styled.div`
  width: 100%;
  margin: 0 48px;
  height: 1px;
  position: relative;
  background-image: linear-gradient(to right, gray 50%, transparent 50%),
    linear-gradient(to right, gray 50%, transparent 50%),
    linear-gradient(to bottom, gray 50%, transparent 50%),
    linear-gradient(to bottom, gray 50%, transparent 50%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 20px 3px, 20px 3px, 3px 20px, 3px 20px;

  @media ${device.laptop} {
    height: 1200px;
    width: 1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const BigDotWrapper = styled.div`
  position: absolute;
  left: ${props => props.left};
  top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  @media ${device.laptop} {
    position: relative;
    flex-direction: row;
    left: -16px;
    align-items: center;
  }

  @media (max-width: 1200px) {
    :nth-of-type(1) {
      left: -1%;
    }
    :nth-of-type(3) {
      left: 22%;
    }
    :nth-of-type(6) {
      left: 58%;
    }
    :nth-of-type(8) {
      left: 86%;
    }
  }
  @media ${device.laptop} {
    left: -16px !important;
  }
  @media ${device.mobileL} {
    :nth-of-type(1) {
      top: 60px;
    }
    :nth-of-type(3) {
      top: 50px;
    }
    :nth-of-type(6) {
      top: 50px;
    }
    :nth-of-type(8) {
      top: 50px;
    }
  }
`

const BigDot = styled.div`
  width: 40px;
  height: 40px;
  background-color: #f6e9ee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 26px;
    height: 26px;
    background-color: #a62651;
    border-radius: 50%;
  }
  @media ${device.laptop} {
    height: 32px;
    min-width: 32px;
    margin-right: 20px;
    div {
      width: 16px;
      height: 16px;
    }
  }
  @media ${device.mobileL} {
    margin-right: 12px;
  }
`

const SmallDot = styled.div`
  width:10px;
  height:10px;
  background-color:#A62651;
  position:absolute;
  top:-5px;
  border-radius:50%;
  z-index:2;
  left:${props => props.left};
  @media ${device.laptopL} {
    :nth-of-type(4)  {
      left:49.2%;
    }
  }
  @media ${device.laptop} {
    left: -5px !important;
    position:relative;
  }
  @media ${device.mobileL} {
    left:-4px;
  }
  @media (max-width:1200px) {
    :nth-of-type(2)  {
      left:20% ;
    }
    :nth-of-type(4)  {
      left:44.4% ;
    }
    :nth-of-type(5)  {
      left:56.4% ;
    }
    :nth-of-type(7)  {
      left:77% ;
  }
  @media ${device.tablet} {

    :nth-of-type(7)  {
      top:-9% ;
  }
  @media ${device.mobileL} {
    :nth-of-type(2)  {
      top:10% ;
    }
    :nth-of-type(9)  {
      top:-19% ;
    }
    :nth-of-type(5)  {
      top:-10px ;
    }
    :nth-of-type(7)  {
      top:-14% ;
  }
`

const LineTop = styled.div`
  width: 23%;
  height: 2px;
  background-color: #231d30;
  position: absolute;
  opacity: 0.1;
  transform: rotate(${props => props.rotateAngle});
  left: ${props => props.leftPos};
  bottom: 20%;
  z-index: 1;
  @media ${device.laptopL} {
    width: 24%;
  }
  @media (max-width: 1200px) {
    width: 36%;
    :nth-of-type(3) {
      bottom: -15%;
      left: 46%;
      transform: rotate(40.5deg);
    }
    :nth-of-type(4) {
      bottom: -15%;
      left: 18%;
      transform: rotate(-40.5deg);
    }
  }
  @media ${device.laptop} {
    width: 2px;
    :nth-of-type(3) {
      bottom: 60%;
      height: 485px;
      left: 120%;
      transform: rotate(20.5deg);
    }
    :nth-of-type(4) {
      top: 50%;
      left: 130%;
      height: 450px;
      transform: rotate(-20.5deg);
    }
  }
  @media ${device.tablet} {
    width: 2px;
  }
`

const LineTopCenter = styled.div`
  width: 2px;
  height: 50%;
  background-color: #231d30;
  position: absolute;
  opacity: 0.1;
  left: 54%;
  bottom: 0%;
  z-index: 1;
  @media (max-width: 1200px) {
    left: 50%;
    height: 200px;
    top: 43%;
  }
  @media ${device.laptopL} {
    height: 55%;
  }
  @media ${device.laptop} {
    height: 2px;
    width: 130px;
    left: 100%;
    top: 33%;
  }
  @media ${device.tablet} {
    width: 110px;
  }
  @media ${device.mobileL} {
    display: none;
  }
`

const LineCardCenter = styled.div`
  width: 2px;
  height: ${props => props.height};
  background-color: #231d30;
  position: absolute;
  opacity: 0.1;
  left: ${props => props.leftPos};
  transform: rotate(${props => props.rotateAngle});
  top: ${props => props.topPos};
  z-index: 1;
  @media ${device.laptopL} {
    height: 70%;
    :nth-of-type(3) {
      left: 82.8%;
      top: 78.5%;
    }
    :nth-of-type(4) {
      left: 13%;
    }
    :nth-of-type(5) {
      left: 59.7%;
      top: 76.9%;
    }
    :nth-of-type(6) {
      height: 104%;
      left: 31.5%;
      top: 63.5%;
      transform: rotate(49.5deg);
    }
    :nth-of-type(7) {
      top: 62%;
      left: 93.5%;
      height: 100%;
      transform: rotate(-48deg);
    }
  }
  @media (max-width: 1200px) {
    height: 120%;
    :nth-of-type(3) {
      left: 82.8%;
      top: 80%;
      height: 125%;
    }
    :nth-of-type(4) {
      left: 13%;
    }
    :nth-of-type(5) {
      left: 60%;
      top: 77%;
      height: 128%;
    }
    :nth-of-type(6) {
      height: 150%;
      left: 31.9%;
      top: 71.5%;
      transform: rotate(32.5deg);
    }
    :nth-of-type(7) {
      top: 56%;
      left: 95.5%;
      height: 170%;
      transform: rotate(-43deg);
    }
  }
  @media ${device.tablet} {
    height: 2px;

    :nth-of-type(3) {
      left: 100.8%;
      top: 85%;
      height: 2px;
      width: 86px;
    }
    :nth-of-type(4) {
      top: 4.4%;
      left: 13%;
      height: 2px;
      width: 246px;
      transform: rotate(-24deg);
    }
    :nth-of-type(5) {
      top: 28.4%;
      left: -6%;
      height: 2px;
      width: 400px;
      transform: rotate(-66deg);
    }
    :nth-of-type(6) {
      height: 2px;
      width: 200px;
      left: 49.9%;
      top: 59.5%;
      transform: rotate(40.5deg);
    }
    :nth-of-type(7) {
      top: 49%;
      left: 101%;
      height: 2px;
      width: 90px;
      transform: rotate(0);
    }
  }
  @media ${device.mobileL} {
    height: 2px;

    :nth-of-type(3) {
      left: 100.8%;
      top: 80.5%;
      height: 2px;
      width: 57px;
    }
    :nth-of-type(4) {
      top: 10.4%;
      left: 13%;
      height: 2px;
      width: 200px;
      transform: rotate(-24deg);
    }
    :nth-of-type(5) {
      top: 34.7%;
      left: 10%;
      height: 2px;
      width: 255px;
      transform: rotate(-63.5deg);
    }
    :nth-of-type(6) {
      height: 2px;
      width: 175px;
      left: 24.9%;
      top: 57%;
      transform: rotate(29.5deg);
    }
    :nth-of-type(7) {
      top: 49%;
      left: 101%;
      height: 2px;
      width: 53px;
      transform: rotate(0);
    }
  }
`

const PointDescription = styled.h5`
  font-size: 24px;
  line-height: 38px;
  font-family: FreightSans;
  font-weight: 600;
  color: ${colors.dark};
  :nth-of-type(2) {
    margin-left: -60px;
  }
  :nth-of-type(3) {
    margin-left: 18px;
  }
  @media ${device.laptop} {
    margin-bottom: 0;
  }
  @media ${device.mobileL} {
    font-size: 20px;
  }
`
const TitleScheme = styled.h3`
  font-family: FreightSans;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: rgba(35, 29, 48, 0.6);
  margin-top: 64px;
  text-align: center;
  @media ${device.tablet} {
    margin: 32px 64px 64px;
  }
  @media ${device.mobileL} {
    margin: -64px 16px 128px;
  }
`

export default Scheme
